import PropTypes from 'prop-types';
import { makePresenter } from 'utils/PropTypesPresenter';

import OrganizationPresenter from 'presenters/OrganizationPresenter';

export const SHARING_SETTINGS = [
  {
    title: 'Inventory',
    description: 'Product Library',
    shareDataField: 'shareProductInventoryWithOrganization',
    anonymouslyShareField: 'shareProductInventoryAnonymously',
    anonymouslyShareFieldVisible: true,
  },
  {
    title: 'Feedback',
    description: 'Product Feedback Data',
    shareDataField: 'shareProductFeedbackWithOrganization',
    anonymouslyShareField: 'shareProductFeedbackAnonymously',
    anonymouslyShareFieldVisible: false,
    dependsOn: ['shareProductInventoryWithOrganization'],
  },
  {
    title: 'Usage',
    description: 'Product Usage',
    shareDataField: 'shareProductUsageWithOrganization',
    anonymouslyShareField: 'shareProductUsageAnonymously',
    anonymouslyShareFieldVisible: false,
    dependsOn: ['shareProductInventoryWithOrganization'],
  },
  {
    title: 'Pricing',
    description: 'Product Pricing',
    shareDataField: 'shareProductPricingWithOrganization',
    anonymouslyShareField: 'shareProductPricingAnonymously',
    anonymouslyShareFieldVisible: false,
    dependsOn: ['shareProductInventoryWithOrganization'],
  },
  {
    title: 'Impact',
    description: 'Impact Analyses',
    shareDataField: 'shareImpactWithOrganization',
    anonymouslyShareField: 'shareImpactAnonymously',
    anonymouslyShareFieldVisible: false,
    dependsOn: [
      'shareProductInventoryWithOrganization',
      'shareProductPricingWithOrganization',
      'shareProductFeedbackWithOrganization',
    ],
  },
];

const shape = {
  organization: OrganizationPresenter.shape().isRequired,
  approved: PropTypes.bool,
  status: PropTypes.string,
  organizationAdminedByMember: PropTypes.bool,
  grades: PropTypes.arrayOf(PropTypes.shape({})),
  subjects: PropTypes.arrayOf(PropTypes.shape({})),
  role: PropTypes.string,
};

export type MembershipPresenterType = PropTypes.InferProps<typeof shape>;

export default makePresenter(shape, {
  isEnabled(membership, flag) {
    return membership[flag];
  },

  organizationName(membership) {
    return membership.organization.name;
  },

  statusObject(membership) {
    switch (this.status(membership)) {
      case 'approved':
        return {
          color: 'success',
          name: 'Approved',
        };
      case 'pending':
        return {
          color: 'warning',
          name: 'Pending',
        };
      case 'rejected':
        return {
          color: 'danger',
          name: 'Rejected',
        };
      default:
        return {
          color: 'default',
          name: 'Unknown',
        };
    }
  },

  statusLabel(membership) {
    if (membership.approved) {
      return 'Approved';
    }

    return 'Pending';
  },

  isApproved(membership) {
    return this.status(membership) === 'approved';
  },
});
